@import 'compass/reset';
@import "compass/utilities/general/clearfix";

$breakpoints: (
  's': 'screen and (min-width: 400px)',
  'm': 'screen and (min-width: 768px)',
  'l': 'screen and (min-width: 1000px)'
) !default;

@mixin mq($breakpoint: m) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin ct_inner {
  width: 95%;
  max-width: 1000px;
  margin: 0 auto;
}

body {
  font-family: 'Signika', 'Kosugi Maru', "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
  font-size: 14px;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
}

.ct_inner {
  @include ct_inner;
}

.pt5 {
  padding-top: 5px;
}

.mv {
  width: 100%;
  min-height: 500px;
  padding: .5em 0 0;
  background: url('/assets/images/paprika/mv_bg.jpg') no-repeat center;
  background-size: cover;
  position: relative;

  .mv__inner {
    @include mq {
      @include ct_inner;
    }
  }

  .mv__text {
    margin-left: 1em;
  }

  .mv-logo {
    img {max-width: 30vw;}
    text-align: center;
    margin-top: 150px;
    @include mq {
      text-align: left;
      margin: 2em 0 0 10vw;
      max-width: 100%;
    }
  }
}

.contact {
  background-color: rgba(0, 0, 0, 0.2);
  //background: #DBD3CA;
  width: 100%;
  color: #FFF;
  padding: 1em 1em;
  position: absolute;
  bottom: 0;

  .contact__inner {
    @include ct_inner;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .mv-under__tel {
    margin-bottom: 20px;
    @include mq() {
      margin: 0;
    }

    .mv-under__tel__top {
      text-shadow: 2px 2px 2px #888;
      margin-bottom: 7px;
      position: relative;
      line-height: 24px;
      padding-left: 40px;

      &:before {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 33px;
        height: 24px;
        content: '';
        background: url('/assets/images/paprika/icon_tel.png') no-repeat;
      }
    }

    .mv-under__tel__number {
      margin-left: 1em;
      margin-bottom: 10px;

      a {
        text-shadow:2px 2px 2px #888;
        font-size: 35px;
        @include mq() {
          font-size: 40px;
        }
        color: inherit;
        text-decoration: none;
        letter-spacing: 0.1em;
      }
    }

    .mv-under__tel__time {
      display: inline-block;
      text-align: center;
      margin: 0 auto;
      padding: 0 1em;
      line-height: 1.8em;

      background: #FFF;
      color: #000;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  .mv-under__email {
    display: none;
    @include mq() {
      display: block;
    }

    .mv-under__email__top {
      margin: 0 0 10px 0;
      text-shadow: 2px 2px 2px #888;
      line-height: 24px;
      position: relative;
      padding-left: 40px;

      &:before {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 33px;
        height: 24px;
        content: '';
        background: url('/assets/images/paprika/icon_mail.png') no-repeat;
      }
    }

    .mv-under__email__bnr {
    }
  }
}

.navi {
  border-bottom: 1px solid #000;

  .navi__list {
    @include ct_inner;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    li {
      text-align: center;
      display: inline-block;
      font-weight: bold;

      font-size: 15px;
      @include mq() {
        font-size: 20px;
      }

      a {
        display: inline-block;
        padding: 30px 0;
        color: inherit;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }

        span {
          display: inline-block;
          border-bottom: 3px solid #000000;
          padding: 0 .2em;
        }
      }
    }
  }
}

.concept {
  text-align: center;
  color: #78544c;
  padding: 2em 1em;
  line-height: 1.5em;
  font-weight: bold;
}

.section_ttl {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  border-bottom: 1px solid #000;
  padding-bottom: .8em;
  margin-bottom: .8em;

  .section_ttl__inner {
    @include mq() {
      @include ct_inner;
      text-align: left;
    }
  }

  span {
    display: inline-block;
    border-bottom: 3px solid #000000;
    padding: 0 .2em;
  }
}

.menu {
  margin-bottom: 30px;

  .tax_note {
    text-align: center;
    margin: 2em 0 2em;
    font-size: 16px;
    color: #a22041;
  }

  .menu_list {
    @include mq {
      @include ct_inner;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 48%;
      }
    }

    .menu_item {
      margin-bottom: 1em;

      .menu_item__ttl {
        background: #DBD3CA;
        color: #78544c;
        font-size: 20px;
        text-align: center;
        padding: .5em 0;
        border-radius: 5px;
        width: 90%;
        margin: 0 auto 20px;
        @include mq {
          width: 100%;
        }
      }

      .menu_item__dtl {
        text-align: center;
        margin-bottom: 20px;
      }

      .main_txt {
        font-size: 18px;
        span + span {
          padding-left: 1em;
        }
      }

      .sub_txt {
        font-size: 13px;
      }

      .menu_sub_list {
        width: 95%;
        margin: 0 auto;
        font-size: 13px;
        display: flex;
        flex-wrap: wrap;

        > li {
          text-align: center;
          width: 50%;
          margin-bottom: 0.5em;
          color: #808080;

          span + span {
            padding-left: 1em;
          }
        }
      }

      .middle_text {
        font-size: 16px;

        span + span {
          padding-left: 1em;
        }
      }

      .middle_text + .middle_text_sub {
        margin-top: 0.5em;
        margin-bottom: 1em;
      }

      .middle_text_sub {
        margin-top: 1em;
      }

      .middle_text + .middle_text {
        margin-top: 1em;
      }

      .middle_text_sub {
        color: #808080;

        span + span {
          padding-left: 1em;
        }
      }

      .middle_note {
        color: #808080;
        font-size: 13px;
        padding-top: .8em;
      }
    }
  }
}


.shop_info {
  margin-bottom: 30px;

  .shop_dtl {

    .shop_dtl__item {
      display: inline-block;
      margin-bottom: 1em;

      width: 50%;

      &.long {
        width: 100%;
      }

      .ttl {
        margin-bottom: .5em;
        font-weight: bold;
        font-size: 16px;
      }

      .body {
        padding-left: 1em;
        @include mq() {
          padding: 1em 0 0 3em;
        }
      }

      .map_link {
        display: inline-block;
        background: #DBD3CA;
        padding: 0.5em;
        line-height: 1.1em;
        border-radius: 3px;
        color: #78544c;
        text-decoration: none;
        margin-left: 2em;
      }
    }
  }
}

.designers {
  .designers_wp {
    @include mq {
      @include ct_inner;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 48%;
      }
    }

    .designer_item {
      margin-bottom: 25px;
      border-bottom: 1px solid #000;
      @include mq {
        border: none;
      }
      &:last-child {
        border: none;
      }

      .designer_item__main {
        .designer_item__main__pic {
          text-align: center;
          margin-bottom: 25px;

          img {
            max-width: 80%;
          }
        }

        .designer_item__main__prof {
          .designer_item__main__name {
            background: #DBD3CA;
            text-align: center;
            padding: 10px 0;
            margin-bottom: 10px;

            > span {
              display: block;

              &.category {
                color: #78544c;
                font-size: 15px;
                margin-bottom: 10px;
              }

              &.name {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 5px;
              }

              &.kana {

              }
            }
          }

          .designer_item__main__text {
            padding: 10px;
            line-height: 1.3em;
          }
        }
      }

      .designer_item__sub {
        padding: 10px;

        .sub__item {
          margin-bottom: 1em;

          .ttl {
            margin-bottom: .8em;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            position: relative;
            padding-left: 40px;

            &:before {
              display: inline-block;
              content: '';
              width: 30px;
              height: 30px;
              position: absolute;
              left: 0;
            }

            &.dryer:before {
              background: url('/assets/images/paprika/dryer.png') no-repeat;
            }

            &.scissor:before {
              background: url('/assets/images/paprika/scissor.png') no-repeat;
            }

            &.cricle:before {
              background: url('/assets/images/paprika/cricle.png') no-repeat;
            }
          }

          .body {
            line-height: 1.3em;
            font-size: 14px;

            &.long span {
              display: block;
            }
            span {
              padding-right: 1em;
              &:last-child {padding-right: 0;}
            }
          }
        }
      }
    }
  }
}

.access {
  .access_wp {
    @include mq {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .access_item {
      text-align: center;
      margin-bottom: 1em;

      @include mq {
        width: 33%;
      }

      &.gmap {
        width: 100%;
        min-height: 240px;
        iframe {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}

footer {
  .footer_logo {
    text-align: center;
    margin-bottom: 25px;
    padding: 25px 0;
    border-top: 1px dotted #CCCCCC;
    border-bottom: 1px dotted #CCCCCC;
    img {
      max-width: 30%;
    }
  }

  .copyright {
    background: #DBD3CA;

    > p {
      text-align: right;
      line-height: 1.3em;
      padding: .3em 1em .3em 0em;
      > span {
        display: inline-block;
      }
    }
  }
}

#floting {
  position: fixed;
  bottom: 3vh;
  right: 5vw;
}